import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import SliderCard from "./SliderCard";

import { collection, getDocs } from "firebase/firestore";
import db from "../../firebase";

const SliderContainer = ({}) => {
  const slider = useRef();

  const [products, setProducts] = useState([]);

  useEffect(() => {
    const productsTemp = [];
    async function fetchAllProducts() {
      const querySnapshot = await getDocs(collection(db, "products"));
      querySnapshot.forEach((doc) => {
        productsTemp.push(doc.data());
      });
    }

    setProducts(productsTemp);

    fetchAllProducts();
  }, []);

  const settings = {
    className: "slider variable-width",
    arrows: false,
    infinite: true,
    swipeToSlide: true,
    centerMode: true,
    focusOnSelect: true,
    draggable: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    touchMove: true,
    useCSS: true,
    swipe: true,
    useTransform: true,
  };

  return (
    <>
      <Slider {...settings} ref={slider}>
        {products
          ? products.map((product, i) => (
              <SliderCard product={product} key={i} />
            ))
          : ""}
      </Slider>
    </>
  );
};

export default SliderContainer;
