// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAnPqDK1uEIf7EiYjXNtDY7SvCl0kI3cJI",
  authDomain: "mesh-ar.firebaseapp.com",
  projectId: "mesh-ar",
  storageBucket: "mesh-ar.appspot.com",
  messagingSenderId: "907925615863",
  appId: "1:907925615863:web:343c65a20ae3a40cf7cff2",
  cors: true,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;
