import React from "react";
import Logo from "../../../assets/icon-100x100.png";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <header className="py-2 shadow-lg bg-primary">
      <Link to="/" className="container flex items-center gap-1 px-5">
        <img src={Logo} alt="Logo" className="h-8" />
        <h1 className="font-semibold text-2xl text-secondaryText">Mesh AR</h1>
      </Link>
    </header>
  );
}

export default Navbar;
