import React from "react";
import { isMobile } from "react-device-detect";

const Content = ({ currentProduct, ViewBtnRef }) => {
  return (
    <>
      <div className="flex justify-center items-center mb-5">
        <a
          target="_blank"
          rel="noreferrer"
          href={currentProduct ? currentProduct?.link : "https://meshable.in/"}
        >
          <button className="w-36 text-lg font-semibold border border-secondaryText text-secondaryText rounded-2xl bg-secondary">
            Buy
          </button>
        </a>
      </div>

      {/* About */}
      <div className="">
        <h4 className="text-md font-medium">About</h4>
        <p className="text-sm">
          {currentProduct
            ? currentProduct.about
            : "A Three Tier Marmelos\nElegant Indoor / Outdoor Garden Metal Plant Stand"}
        </p>
      </div>

      {/* Specifications */}
      <div className="mt-2">
        <h4 className="text-md font-medium">Specifications</h4>
        <table className="text-sm">
          <tbody>
            <tr>
              <td>Weight:</td>
              <td>{currentProduct ? currentProduct.weight : "10.5"} kg</td>
            </tr>
            <tr>
              <td>Dimensions:</td>
              <td>
                {currentProduct
                  ? currentProduct.dimensions
                  : "68.5 × 30 × 15 cm"}
              </td>
            </tr>
            <tr>
              <td>Color:</td>
              <td>
                <div className="flex">
                  {currentProduct.color ? (
                    currentProduct.color.map((clr, j) => {
                      return (
                        <div
                          key={j}
                          className="mr-1 border border-black rounded-full w-4 aspect-square"
                          style={{ backgroundColor: clr }}
                        ></div>
                      );
                    })
                  ) : (
                    <>
                      <div className="mr-1 border border-black rounded-full bg-white w-4 aspect-square"></div>
                      <div className="mr-1 border border-black rounded-full bg-red-600 w-4 aspect-square"></div>
                    </>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td>Installation Type:</td>
              <td>
                {/* {currentProduct
                  ? currentProduct.placement.replace(/^\w/, (c) =>
                      c.toUpperCase()
                    )
                  : "Floor Mounted"} */}
                Mounted
              </td>
            </tr>
            <tr>
              <td>Load Capacity:</td>
              <td>
                {currentProduct
                  ? currentProduct.loadCarry +
                    " kg/" +
                    currentProduct.productName
                  : "30 Kg (each Tier)"}
              </td>
            </tr>
          </tbody>
        </table>
        {isMobile ? (
          <div className="w-full mt-2 flex justify-center">
            <button
              slot="ar-button"
              onClick={() => {
                ViewBtnRef.current.click();
              }}
              className="px-2 py-1 text-secondaryText font-semibold bg-secondary border border-secondaryText rounded-2xl"
            >
              View in Your Space
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Content;
