import React from "react";
import { Route, Routes } from "react-router-dom";

// JSX IMPORTS
import ModelView from "./pages/ModelView";
import Home from "./pages/Home/Home";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path=":id" element={<ModelView />} />
        <Route path="/no-match" element={"NO MATCH"} />
      </Routes>
    </>
  );
}

export default App;
