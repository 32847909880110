import React from "react";
import { QRCode } from "react-qrcode-logo";
// import { useLocation } from "react-router-dom";
import QRLogo from "../assets/icon-100x100.png";
import { useParams } from "react-router-dom";

const QR = ({ setIsQROpen }) => {
  const params = useParams();
  const { id } = params;
  return (
    <>
      <div className="w-screen h-screen flex flex-col justify-center items-center top-0 relative">
        <div className="bg-black w-screen h-screen absolute opacity-50" />
        <div className="absolute top-1/4 z-20 bg-white p-2 rounded-3xl border-2 border-black flex flex-col justify-center items-center">
          <h1 className="font-bold text-xl">Open on Phone</h1>
          <h2 className="text-lg -my-1">Scan the following qr code</h2>
          <QRCode
            value={"https://mesh-ar.meshable.in/" + id}
            logoWidth="50"
            logoImage={QRLogo}
            enableCORS="true"
            ecLevel="H"
            qrStyle="dots"
            logoPadding="2"
            fgColor="#861a20"
            {...{
              eyeRadius: [
                {
                  outer: [50, 0, 0, 0],
                },
                {
                  outer: [0, 50, 0, 0],
                },
                {
                  outer: [0, 0, 0, 50],
                },
              ],
            }}
          />
          <button
            className="px-2 py-1 text-secondaryText font-semibold bg-secondary border border-secondaryText rounded-2xl"
            onClick={() => {
              setIsQROpen(false);
            }}
          >
            Close
          </button>
        </div>
      </div>
    </>
  );
};

export default QR;
