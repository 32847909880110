import React, { useEffect, useState } from "react";
import BannerImg from "../../../assets/banner.jpg";
import { Link } from "react-router-dom";
import Exhibit from "../../../assets/IMG20230529154619.jpg";

import { collection, onSnapshot, query } from "firebase/firestore";
import db from "../../../firebase";
import { TbAugmentedReality } from "react-icons/tb";
import Slider from "react-slick";

import HowToUse from "../../../assets/HowToUseGIF.gif";

function Content() {
  const [products, setProducts] = useState([]);

  const settings = {
    className: "slider variable-width",
    arrows: false,
    dots: false,
    pauseOnHover: false,
    infinite: true,
    speed: 200,
    autoplay: true,
    variableWidth: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    focusOnSelect: false,
    touchMove: false,
    useCSS: true,
    swipe: false,
  };

  const words = [
    "Make Spaces Beautiful",
    "Freedom with Modularity",
    "A conversation starter",
    "Organizers for Urban Gardens",
    "Unique furniture with Mesh",
  ];

  useEffect(() => {
    const q = query(collection(db, "products"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const productsTemp = [];
      querySnapshot.forEach((doc) => {
        productsTemp.push(doc.data());
      });

      setProducts(productsTemp);
      // console.log("productsTemp", productsTemp);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      {/* BANNER */}

      <div
        className="bg-cover bg-no-repeat bg-[-600px] md:bg-center py-36 min-w-max"
        style={{
          backgroundImage: `url("https://meshable.in/wp-content/uploads/2022/06/filantus-pkg-rm-1.jpg")`,
        }}
      >
        <div className="container px-10">
          <h1 className="text-4xl md:text-5xl lg:text-6xl text-primary font-medium mb-4 capitalize [text-shadow:_2px_4px_5px_rgb(161_12_27_/_100%)] drop-shadow-2xl">
            best collection for <br /> home decoration
          </h1>

          <div className="hidden md:block">
            <Slider {...settings}>
              {words.map((word, index) => (
                <p
                  key={index}
                  className="w-1/2 rounded-lg cursor-pointer text-white text-xl font-semibold [text-shadow:_1px_2px_5px_rgb(250_196_71_/_100%)] drop-shadow-md"
                >
                  {word}
                </p>
              ))}
            </Slider>
          </div>

          <div className="mt-12">
            <Link
              to="https://meshable.in/"
              className="bg-primary border border-secondaryText text-secondaryText px-6 py-3 font-medium rounded-md hover:text-secondary hover:border-secondary duration-300 ease-in-out shadow-md shadow-secondaryText hover:shadow-secondary"
            >
              Shop Now
            </Link>
          </div>
        </div>
      </div>

      <section className="px-8 py-4 flex flex-col md:flex-row gap-5">
        <div className="md:w-1/2">
          <h1 className="py-2 text-2xl font-bold tracking-wide">
            What is Mesh AR?
          </h1>
          <div
            className="w-full aspect-square shadow-xl drop-shadow-xl rounded-lg border overflow-hidden bg-cover bg-center p-4 lg:leading-10 lg:text-2xl flex flex-col justify-center items-center"
            style={{ backgroundImage: `url(${Exhibit})` }}
          >
            <h4 className="text-primary font-bold [text-shadow:_0px_0px_100px_rgb(255_255_255_/_100%)] tracking-wider text-justify">
              Mesh AR allows users to project virtual 3D models of the Meshable
              products into their real-world environment. By utilizing their
              smartphones or other compatible devices, users can view the
              products as if they were physically present in their own space.
              This provides a realistic and interactive way for customers to
              visualize how the meshables would look in their home, office, or
              any other location.
            </h4>
          </div>
        </div>

        <div className="md:w-1/2">
          <h1 className="py-2 text-2xl font-bold tracking-wide">
            How to Use It?
          </h1>
          <div className="w-full aspect-square shadow-xl drop-shadow-xl rounded-lg border overflow-hidden bg-cover bg-center p-4 lg:leading-10 lg:text-2xl flex flex-col justify-center items-center">
            <img src={HowToUse} alt="How To Use" className="w-full h-full" />
          </div>
        </div>
      </section>

      <main className="px-8 py-4">
        <h1 className="text-xl font-bold tracking-wide py-2">
          Meshable Products:
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-3">
          {products.length
            ? products.map((product, i) => {
                return (
                  <div
                    key={i}
                    className="md:w-52 shadow-lg drop-shadow-lg bg-primary rounded-md border p-3"
                  >
                    <img
                      src={product?.poster}
                      alt={product?.productName}
                      className="aspect-square object-cover border-b"
                    />
                    <div className="leading-3 px-3 py-2 flex items-center justify-between">
                      <div>
                        <h2 className="font-medium text-xl md:text-lg lg:text-base">
                          {product?.productName}
                        </h2>

                        {/* PRICE */}
                        {/* <p className="text-base md:text-sm">
                          Price: {product?.price}
                        </p> */}
                      </div>
                      <a
                        href={product?.productName}
                        className="justify-end bg-secondary rounded-lg"
                      >
                        <TbAugmentedReality className="text-secondaryText text-3xl" />
                      </a>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </main>
    </>
  );
}

export default Content;
