import React, { useEffect, useRef, useState, Suspense } from "react";

import { isMobile } from "react-device-detect";

// FIREBASE
import db from "../firebase";
import { doc, getDoc } from "firebase/firestore";

import QRCodeButton from "../components/QRCodeButton";
import ModelSheet from "../components/ModelSheet";

import "./style.css";
import { useParams } from "react-router-dom";

function ModelView() {
  const params = useParams();

  const ModRef = useRef();
  const ViewBtnRef = useRef();

  const [currentProduct, setCurrentProduct] = useState({});
  const [src, setSrc] = useState(null);
  const [material, setMaterial] = useState([]);

  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const wordsList = [
    "Just a sec, Its loading...",
    "Take a step back and get the full picture!",
    "Give it some space to play!",
    "Make sure you have good light surrounding",
    "Explore and discover the best view!",
  ];

  useEffect(() => {
    const { id } = params;
    // console.log(id);
    document.title = id + " | Mesh AR";

    const unsub = async () => {
      const docRef = doc(db, "products", id);
      const docSnap = await getDoc(docRef);

      // console.log(docSnap.data());

      setCurrentProduct(docSnap.data());
    };

    setSrc(currentProduct?.glbUrl);

    // console.log("currentProduct 48", currentProduct);
    unsub();
  }, []);

  useEffect(() => {
    const fetchMaterial = async () => {
      setTimeout(async () => {
        const modRefMaterial = await ModRef?.current?.model?.materials;
        setMaterial(await modRefMaterial);
      }, 5000);
    };

    fetchMaterial();
  }, [ModRef]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentWordIndex(
        (currentWordIndex) => (currentWordIndex + 1) % wordsList.length
      );
    }, 2000);

    return () => clearInterval(intervalId);
  }, [wordsList.length]);

  return (
    <div>
      <model-viewer
        id="model"
        ref={ModRef}
        src={src ? src : currentProduct?.glbUrl}
        alt={currentProduct?.productName}
        poster={currentProduct?.poster}
        ar-placement={currentProduct?.placement}
        exposure="1"
        loading="eager"
        camera-controls
        ar
        disable-pan
        disable-tap
        ar-scale="fixed"
        ar-modes="webxr scene-viewer quick-look"
        shadow-intensity="1"
        shadow-softness="1"
        touch-action="pan-y"
      >
        {/* ERROR && LOADING SCREEN && PROMPT */}
        <div id="progress" slot="progress-bar">
          <div className="bar"></div>
        </div>
        <div id="ar-prompt">
          <img src="https://modelviewer.dev/assets/hand.png" alt="Hand" />
        </div>
        <button id="ar-failure">
          <div className="fading-words">
            {wordsList.map((word, index) => (
              <span
                key={word}
                className={`word ${currentWordIndex === index ? "active" : ""}`}
              >
                {word}
              </span>
            ))}
          </div>
        </button>

        {!isMobile ? (
          <QRCodeButton id={currentProduct.id} />
        ) : (
          <button
            slot="ar-button"
            ref={ViewBtnRef}
            className="px-2 py-1 text-secondaryText font-semibold bg-secondary border border-secondaryText rounded-2xl"
            style={{
              position: "absolute",
              bottom: "25%",
              left: "50%",
              translate: "-50% -5%",
            }}
          >
            View in Your Space
          </button>
        )}

        <ModelSheet
          currentProduct={currentProduct}
          ViewBtnRef={ViewBtnRef}
          setSrc={setSrc}
          material={material}
        />
      </model-viewer>
    </div>
  );
}

export default ModelView;
