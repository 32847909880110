import React, { useState } from "react";
import { RxExternalLink } from "react-icons/rx";
import { TbPlant2, TbPlant2Off } from "react-icons/tb";

import { Link } from "react-router-dom";

const Header = ({ currentProduct, material, setSrc }) => {
  const On = "border-blue-500 text-blue-500 bg-white";
  const Off = "border-white text-white bg-blue-500";
  const [toggle, setToggle] = useState(false);

  const changeColor = (clr) => {
    if (material) {
      material[0].pbrMetallicRoughness.setBaseColorFactor(clr);
    }
  };

  return (
    <>
      <div className="flex justify-between">
        <div className="w-24 h-28 mr-2 flex justify-center items-center overflow-hidden border border-secondaryText rounded-2xl">
          <img
            src={
              currentProduct.poster
                ? currentProduct.poster
                : "https://firebasestorage.googleapis.com/v0/b/mesh-ar.appspot.com/o/Marmelos%20-%203T%2FMarmelosImage.jpg?alt=media&token=1db39516-9783-4b87-bc47-ac4a5b28f48a"
            }
            alt={currentProduct?.productName}
            className="p-1"
          />
        </div>
        <div className="grow flex flex-col justify-start items-start">
          <h5 className="mx-1 text-2xl font-semibold">
            {currentProduct ? currentProduct.productName : "Marmelos - 3T"}
          </h5>
          <p className="mx-1">
            {currentProduct.category
              ? currentProduct?.category
                  .split("-")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")
              : "Plant Organizer"}
          </p>

          {/* PRICE */}

          {/* <h5 className="mx-1 text-xl font-semibold">
            {currentProduct.price ? `₹${currentProduct.price}` : "₹6,399"}
          </h5> */}

          <div className="flex justify-center items-center mt-1">
            {currentProduct.color ? (
              currentProduct.color.map((clr, j) => {
                return (
                  <div
                    key={j}
                    className="w-6 mr-1 border border-black rounded-full cursor-pointer"
                    onClick={() => {
                      changeColor(clr);
                    }}
                  >
                    <div
                      className="aspect-square rounded-full m-px"
                      style={{ backgroundColor: clr }}
                    ></div>
                  </div>
                );
              })
            ) : (
              <>
                <div
                  className="w-6 mr-1 border border-black rounded-full cursor-pointer"
                  onClick={() => {
                    changeColor("white");
                  }}
                >
                  <div
                    className="aspect-square rounded-full m-px"
                    style={{ backgroundColor: "white" }}
                  ></div>
                </div>
                <div
                  className="w-6 mr-1 border border-black rounded-full cursor-pointer"
                  onClick={() => {
                    changeColor("red");
                  }}
                >
                  <div
                    className="aspect-square rounded-full m-px"
                    style={{ backgroundColor: "red" }}
                  ></div>
                </div>
              </>
            )}
            {currentProduct && currentProduct.glbFileWO ? (
              <button
                className={`p-1 border text-2xl rounded-full flex justify-center items-center ${
                  toggle ? On : Off
                }`}
                onClick={() => {
                  // console.log("clck");
                  setToggle(!toggle);
                  setSrc(
                    toggle ? currentProduct.glbFile : currentProduct.glbFileWO
                  );
                }}
              >
                {toggle ? <TbPlant2Off /> : <TbPlant2 />}
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="w-10 h-10 flex justify-center items-center">
          <a
            href={
              currentProduct ? currentProduct?.link : "https://meshable.in/"
            }
            target="_blank"
          >
            <RxExternalLink className="text-3xl text-secondaryText" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Header;
