import React from "react";
import { Link } from "react-router-dom";

const SliderCard = ({ product }) => {
  return (
    <>
      <a
        className="w-28 h-28 overflow-hidden border border-solid rounded-2xl flex jusify-center items-center relative bg-white cursor-pointer"
        style={{
          borderColor: product.placement === "floor" ? "#60a5fa" : "#fca5a5",
        }}
        href={"/" + product.productName}
      >
        <img
          src={product?.poster}
          className="h-24 w-32 mb-2 object-scale-down absolute object-center"
          alt={product?.productName}
        />
        <div
          className="w-full bottom-0 rounded-xl absolute bg-red-300 border-l border-t border-r flex h-6 justify-between items-center"
          style={{
            borderColor: product.placement === "floor" ? "#60a5fa" : "#fca5a5",
            backgroundColor:
              product.placement === "floor" ? "#60a5fa" : "#fca5a5",
          }}
        >
          <h4
            className="w-full font-bold text-black text-center truncate"
            style={{ fontSize: "14px" }}
          >
            {product?.productName}
          </h4>
        </div>
      </a>
    </>
  );
};

export default SliderCard;
