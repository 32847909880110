import React from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";

import Content from "./elements/Content";
import Divider from "./elements/Divider.jsx";
import Header from "./elements/Header";
import SliderContainer from "./elements/SliderContainer";

export default function ModelSheet({
  currentProduct,
  ViewBtnRef,
  setSrc,
  material,
}) {
  return (
    <>
      <BottomSheet
        open
        skipInitialTransition
        blocking={false}
        scrollLocking={false}
        expandOnContentDrag={true}
        header={
          <Header
            currentProduct={currentProduct}
            material={material}
            setSrc={setSrc}
          />
        }
        snapPoints={({ maxHeight }) => [
          maxHeight - maxHeight / 10,
          maxHeight / 3.7,
          maxHeight * 0.6,
        ]}
      >
        <div className="m-5 flex flex-col overflow-hidden">
          <Content currentProduct={currentProduct} ViewBtnRef={ViewBtnRef} />
          <Divider />
          <SliderContainer />
        </div>
      </BottomSheet>
    </>
  );
}
